<template>
  <div class="pag">
    <template v-if="isshow"
      ><van-empty description="暂无可使用的代金券"
    /></template>
    <template>
      <div class="pagitem_itm">
        <div
          class="succesitem"
          :class="classFilter(index, enable)"
          v-for="(item, index) in successarr"
          :key="item.id"
          @click="
            activeclick(
              index,
              item.ticketInfo.amount,
              item.enable,
              item.msg,
              item.customer_id,
              item.trade_no
            )
          "
        >
          <div class="succesitem_1">
            <div class="succesitem_1_1">
              <div class="succesitem_1_1_font1">
                <span class="succesitem_1_1_font1_qian">￥</span
                >{{ item.ticketInfo.amount }}
              </div>
              <div class="pagclass1_2_font2">
                {{
                  item.ticketInfo.min_level > 0
                    ? "满" + item.ticketInfo.min_level * 1 + "使用"
                    : "无门槛使用"
                }}
              </div>
            </div>
            <div class="succesitem_1_2">
              <div class="succesitem_1_2_font1">
                <p v-html="item.ticketInfo.title || '全场通用·代金券'"></p>
              </div>
              <div class="succesitem_1_2_font2">
                有效期:{{ item.out_time | datetime }}
              </div>
            </div>
            <div class="succesitem_1_3">
              <img :src="item.ticketInfo.image_url" class="succesitem_1_img" />
            </div>
          </div>

          <div class="succesitem_2">
            <div
              :class="
                iconistrue == false && activeindex == index
                  ? 'iconaction'
                  : 'succesitem_2_1'
              "
            >
              <div v-for="(item2, index2) in item.msg" :key="index2">
                {{ item2 }}
              </div>
            </div>
            <div class="succesitem_2_2" @click="incoclick(index)">
              查看详情
              <van-icon
                name="arrow-down"
                v-if="iconistrue == true && activeindex == index ? true : false"
                color="#865002 "
                class="vantincn"
                size="0.2rem"
              />
              <van-icon
                name="arrow-up"
                v-if="
                  iconistrue == false && activeindex == index ? true : false
                "
                class="vantincn"
                color="#865002 "
              />
            </div>
          </div>
        </div>

        <!--所有有效的案例-->
        <!-- <div
          class="pagclass"
          v-for="(item, index) in successarr"
          :class="classFilter(index, enable)"
          :key="item.id"
          @click="
            activeclick(
              index,
              item.ticketInfo.amount,
              item.enable,
              item.msg,
              item.customer_id,
              item.trade_no
            )
          "
        >
          <div class="pagclass1">
            <div class="pagclass1_1">￥{{ item.ticketInfo.amount }}</div>
            <div class="pagclass1_2">
              {{
                item.ticketInfo.min_level > 0
                  ? "满" + (item.ticketInfo.min_level * 1) + "使用"
                  : "无门槛使用"
              }}
            </div>
          </div>

          <div class="pagclass2">
            <div class="pagclass2_1">
              <div class="pagclass2_1_1">
                <p v-html="item.ticketInfo.title || '全场通用·代金券'"></p>
              </div>
              <div class="pagclass2_1_2">
                有效期:{{ item.out_time | datetime }}
              </div>
            </div>
            <div class="pagclass2_2">
              <img :src="item.ticketInfo.image_url" class="imgclass" />
            </div>
          </div>
        </div> -->

        <div class="erroritem" v-for="item in consolearr" :key="item.id">
          <div class="erroritem_1">
            <div class="succesitem_1_1">
              <div class="erroritem_1_1_font1">
                <span class="erroritem_1_1_font1_qian">￥</span
                >{{ item.ticketInfo.amount }}
              </div>
              <div class="errorpagclass1_2_font2">
                {{
                  item.ticketInfo.min_level > 0
                    ? "满" + item.ticketInfo.min_level * 1 + "使用"
                    : "无门槛使用"
                }}
              </div>
            </div>
            <div class="succesitem_1_2">
              <div class="erroritem_1_2_font1">
                <p v-html="item.ticketInfo.title || '全场通用·代金券'"></p>
              </div>
              <div class="erroritem_1_2_font2">
                有效期:{{ item.out_time | datetime }}
              </div>
            </div>
            <div class="succesitem_1_3">
              <img :src="item.ticketInfo.image_url" class="succesitem_1_img" />
            </div>
          </div>

          <div class="succesitem_2">
            <!-- <div :class="iconistrue==false  && activeindex==index?'erroriconaction':'errorsitem_2_1'">限商品现价+打包费+所有金额满100元限商品现价+打包费+所有金额满100元限商品现价+打包费+所有金额满100元</div>
      <div class="erroritem_2_2" @click="incoclick(index)">查看详情
        <van-icon name="arrow-down"  v-if="iconistrue==true && activeindex==index?true:false" color="#865002 " class="vantincn" size="0.2rem"/>
        <van-icon name="arrow-up" v-if="iconistrue==false  && activeindex==index?true:false" class="vantincn" color="#865002 "/>
        </div> -->
            <div class="errorsitem_2_1">
              {{ item.msg }}
              <!-- <div v-for="(item2,index2) in item.msg" :key="index2">
           {{item2}}
           </div> -->
            </div>
            <!-- <div class="erroritem_2_2" @click="incoclick(index)"> -->
            <!-- <van-icon name="arrow-down"  v-if="iconistrue==true && activeindex==index?true:false" color="#865002 " class="vantincn" size="0.2rem"/>
        <van-icon name="arrow-up" v-if="iconistrue==false  && activeindex==index?true:false" class="vantincn" color="#865002 "/> -->
            <!-- </div> -->
          </div>
        </div>

        <!--所有失败的数据-->
        <!-- <div class="pagclassactivebj" v-for="item in consolearr" :key="item.id">
          <div class="pagclass1">
            <div class="pagclass1_1_item">￥{{ item.ticketInfo.amount }}</div>
            <div class="pagclass1_2">
              {{
                item.ticketInfo.min_level > 0
                  ? "满" + (item.ticketInfo.min_level * 1) + "使用"
                  : "无门槛使用"
              }}
            </div>
          </div>

          <div class="pagclass2">
            <div class="pagclass2_1">
              <div class="pagclass2_1_1">
                <p v-html="item.ticketInfo.title || '全场通用·代金券'"></p>
              </div>
              <div class="pagclass2_1_2">
                有效期:{{ item.out_time | datetime }}
              </div>
            </div>
            <div class="pagclass2_2">
              <img :src="item.ticketInfo.image_url" class="imgclass" />
            </div>
          </div>
        </div> -->
      </div>
    </template>
    <div class="pagbuttom">
      <button class="buttomclick2" v-if="listarr.length > 0" @click="noUse">
        暂不使用
      </button>
      <button class="buttomclick" @click="Kimsvolume">确定</button>
    </div>
  </div>
</template>

<script>
import { getdiscountcouponlist } from "@/api/pay";
import Vue from "vue";
import moment from "moment";
import { Dialog } from "vant";
Vue.filter("datetime", function (value) {
  return moment(value * 1000).format("YYYY-MM-DD");
});
export default {
  name: "couponslist",
  data() {
    return {
      data: {},
      subData: {},
      activeindex: 0, //选中
      listarr: [], //所有数据
      msg: "", //提示信息
      enable: "", //当前的状态
      successarr: null, //可以使用数组
      consolearr: null, //无法使用数组
      isshow: null,
      iconistrue: true, //符合下拉
      iconisfalse: false, //符号上拉
    };
  },
  methods: {
    noUse() {
      let max_ticket_amount = 0;
      this.listarr.forEach((item) => {
        if (item.enable == 1 && item.amount > max_ticket_amount) {
          max_ticket_amount = item.amount;
        }
      });
      if (max_ticket_amount > 0) {
        Dialog.confirm({
          title: "友情提示",
          message:
            "确定不使用" +
            (this.data.from == 1 ? "市场" : "档口") +
            "代金券么,最高享受" +
            max_ticket_amount +
            "元优惠!",
        })
          .then(() => {
            if (this.data.from == 0) {
              this.data.select_ticket_index = -1;
            } else {
              this.data.select_merchant_ticket_index = -1;
            }
            this.$router.replace({
              name: "pay",
              path: "/pay",
              params: {
                payInfo: JSON.stringify(this.data),
              },
              query: {
                merchant_id: this.subData.merchant_id,
                booth_id: this.subData.booth_id,
              },
            });
          })
          .catch(() => {});
      } else {
        if (this.data.from == 0) {
          this.data.select_ticket_index = -1;
        } else {
          this.data.select_merchant_ticket_index = -1;
        }
        this.$router.replace({
          name: "pay",
          path: "/pay",
          params: {
            payInfo: JSON.stringify(this.data),
          },
          query: {
            merchant_id: this.subData.merchant_id,
            booth_id: this.subData.booth_id,
          },
        });
      }
    },
    //查看详情的具体信息
    incoclick(index) {
      if (this.activeindex == index) {
        this.iconistrue = !this.iconistrue; //符合下拉
      }
    },
    //返回
    onClickLeft() {
      this.$router.push({
        path: "/pay",
        query: this.subData,
      });
    },
    classFilter(index, enable) {
      if (enable == 1) {
        if (this.activeindex == index) {
          return "activeicon";
        }
      }
    },

    Kimsvolume() {
      if (this.enable == 0) {
        this.$toast.fail("暂无可使用的代金券");
      } else {
        if (this.activeindex == -1) {
          this.$toast.fail("请选择可使用的代金券");
          return;
        }
        if (this.data.from == 0) {
          this.data.select_ticket_index = this.activeindex;
        } else {
          this.data.select_merchant_ticket_index = this.activeindex;
        }
        this.$router.replace({
          name: "pay",
          path: "/pay",
          params: {
            payInfo: JSON.stringify(this.data),
          },
          query: {
            merchant_id: this.subData.merchant_id,
            booth_id: this.subData.booth_id,
          },
        });
      }
    },
    activeclick(index, amount, enable, msg, customer_id, trade_no) {
      if (enable == 1) {
        this.activeindex = index;
        this.msg = msg;
        this.enable = enable;
        this.subData.amount = amount;
        this.subData.customer_id = customer_id;
        this.subData.trade_no = trade_no;
        this.subData.index = index;
      } else {
        this.enable = enable;
        this.msg = msg;
      }
    },

    getdiscountcoupon() {
      //支付页面
      let money = this.data.pay_amount_no_ticket; //支付金额不含优惠券
      //获取优惠券的逻辑
      getdiscountcouponlist({
        is_mini: 0,
        market_id: this.subData.market_id,
        mid: this.subData.mid,
        from: this.data.from ? this.data.from : 0, //0-市场卷，1-商户卷
        money: money,
        status: 0,
        page: 1,
        pageSize: 200,
      }).then((res) => {
        // console.log(res);
        if (res.resultCode == 1) {
          this.listarr = res.resultInfo.list;
          if (res.resultInfo.list.length == 0) {
            this.isshow = true;
          } else {
            this.isshow = false;
          }
          if (res.resultInfo.list.length > 0) {
            if (res.resultInfo.list[0].enable == 1) {
              this.enable = res.resultInfo.list[0].enable;
            }
            this.successarr = res.resultInfo.list.filter(
              (item) => item.enable == 1
            );
            this.consolearr = res.resultInfo.list.filter(
              (item) => item.enable == 0
            );
          }
          //分组
        }
      });
    },
  },
  created() {
    this.$navigation.on("back", (to, from) => {
      to.route.params.payInfo = JSON.stringify(this.data);
    });
    this.enable = "";
    let data = this.$route.query.data;
    data = JSON.parse(data);
    this.data = data;
    this.subData = data.subData;
    //这边根据不同的来源，获取不同的优惠券
    if (this.data.from == 1) {
      this.activeindex = data.select_merchant_ticket_index
    } else {
      this.activeindex = data.select_ticket_index
    }
    this.getdiscountcoupon();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@w: 0.0181rem;
.pag {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  overflow-y: auto;
}
.van-nav-bar {
  width: 100vw;
  height: 0.92rem;
}
.pagitem {
  width: 6.2rem;
  // height:auto;
  background: #ffffff;
  border-radius: 0.16rem;
  margin-top: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow-y:auto;
  // background-color: red;
  margin-bottom: 1.8rem;
}
.pagclass {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  width: 6.3rem;
  height: 1.6rem;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/bj.png");
  display: flex;
}
.active {
  position: relative;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/bj2.png");
}
.active::before {
  content: "";
  position: absolute;
  width: 1.12rem;
  height: 0.3rem;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/bj3.png");
}
.pagclass1 {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.pagclass1_1 {
  //  margin-top: 0.1rem;//
  font-size: 0.4rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #fd6700;
}
.pagclass1_2 {
  // margin-top: 0.1rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(134, 80, 2, 0.5);
}
.pagclass2 {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagclass2_2 {
  width: 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagclass2_1 {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.imgclass {
  width: 1rem;
  height: 1rem;
}
.pagclass2_1_1 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #865002;
}
.pagclass2_1_2 {
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(134, 80, 2, 0.5);
}
.pagbuttom {
  width: 100vw;
  z-index: 111;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0 10 * @w;
}

.pagbuttom2 {
  z-index: 111;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.buttomclick {
  z-index: 112;
  position: relative;
  width: 6.9rem !important;
  height: 0.88rem;
  background: linear-gradient(90deg, #6dd572 0%, #58b85c 100%);
  border-radius: 0.16rem;
  box-shadow: 0px 0.1rem 0.24rem 0px rgba(0, 180, 112, 0.25);
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0 5 * @w;
}

.buttomclick2 {
  z-index: 112;
  position: relative;
  width: 6.9rem !important;
  height: 0.88rem;
  background: linear-gradient(90deg, #ff0602 0%, #ff0602 100%);
  border-radius: 0.16rem;
  box-shadow: 0px 0.1rem 0.24rem 0px rgba(0, 180, 112, 0.25);
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0 5 * @w;
}

.activebj {
  background-size: 100% 100%;
  background-image: url("~@/assets/img/bj02.png");
}

//对失败数组布局
.pagclassactivebj {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  width: 6.3rem;
  height: 1.6rem;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/bj02.png");
  display: flex;
}

.pagclass1_1_item {
  font-size: 0.4rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #865002;
  margin-top: -0.2rem;
}

/deep/ .van-nav-bar__text {
  color: #323233;
}
/deep/ .van-icon {
  color: #323233;
}

//从新布局
.pagitem_itm {
  width: 100vw;
  height: fit-content;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.8rem;
}

.succesitem {
  width: 6.9rem;
  // height:2.2rem;
  height: fit-content;
  background: linear-gradient(45deg, #ffd6ba 0%, #ffece2 51%, #ffdfcb 100%);
  border-radius: 0.12rem;
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.succesitem_1 {
  width: 6.3rem;
  height: 1.46rem;
  display: flex;
  border-bottom: 1px dashed rgba(255, 87, 0, 0.4);
}
.succesitem_2 {
  width: 6.3rem;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.succesitem_1_1 {
  height: 100%;
  width: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.succesitem_1_2 {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.succesitem_1_3 {
  position: relative;
  width: 1.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.succesitem_1_img {
  width: 0.9rem;
  height: 0.9rem;
}
.succesitem_1_1_font1 {
  font-size: 0.52rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #fd6700;
}
.succesitem_1_1_font2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #865002;
}
.succesitem_1_1_font1_qian {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fd6700;
}
.pagclass1_2_font2 {
  width: 100%;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #865002;
  text-align: center;
  margin-top: 0.12rem;
}
.succesitem_1_2_font1 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #865002;
}
.succesitem_1_2_font2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #865002;
  margin-top: 0.22rem;
}
.succesitem_2_1 {
  width: 4.52rem;
  height: 0.32rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(134, 80, 2, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.6rem;
}
.succesitem_2_2 {
  height: 100%;
  width: 1.5rem;
  // height:0.32rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(134, 80, 2, 0.5);
  display: flex;
  // align-items:flex-start;
  // justify-content: flex-start;
}
.vantincn {
  margin-left: 0.2rem;
}
.iconaction {
  width: 4.52rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(134, 80, 2, 0.5);
  height: fit-content;
  margin-right: 0.4rem;
}

.activeicon {
  position: relative;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/xuanzhong.png");
}
.activeicon::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1.12rem;
  height: 0.3rem;
  background-size: 100% 100%;
  background-image: url("~@/assets/img/biaoti.png");
}

//失败
.erroritem {
  width: 6.9rem;
  // height:2.2rem;
  height: fit-content;
  background: #eeeeee;
  border-radius: 0.12rem;
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.erroritem_1_1_font1 {
  font-size: 0.52rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #4a4a4a;
}
.erroritem_1_1_font1_qian {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a4a4a;
}
.errorpagclass1_2_font2 {
  width: 100%;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
  text-align: center;
  margin-top: 0.12rem;
}
.erroritem_1_2_font1 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
}
.erroritem_1_2_font2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #4a4a4a;
  margin-top: 0.22rem;
}
.erroritem_1 {
  width: 6.3rem;
  height: 1.46rem;
  display: flex;
  border-bottom: 1px dashed #ffffff;
}

.erroriconaction {
  width: 4.52rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  height: fit-content;
}

.errorsitem_2_1 {
  width: 100%;
  height: fit-content;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  //  white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
}
.erroritem_2_2 {
  height: 100%;
  width: 1.5rem;
  // height:0.32rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  display: flex;
  // align-items:flex-start;
  // justify-content: flex-start;
}
</style>